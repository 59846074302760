import * as React from "react";
import _ from "lodash";
import { PostDetailsWrapper, PostDescriptionWrapper, PostDescription } from "./post-details.style";

import Toc, { Heading } from "../toc";
import { MDXRenderer } from "gatsby-plugin-mdx";

import { GhostDiv } from "./post-details.style";

type PostDetailsProps = {
  title: string;
  date?: string;
  description: any;
  tags?: [];
  className?: string;
  category?: string;
  author?: string;
  containerWidth?: string;
  fontSizeDesktop?: any;
  tableOfContents: [Heading];
};

const PostDetails: React.FunctionComponent<PostDetailsProps> = ({
  title,
  date,
  description,
  tags,
  className,
  category,
  author,
  tableOfContents,
  containerWidth,
  fontSizeDesktop,
  ...props
}) => {
  return (
    <PostDetailsWrapper {...props}>
      <Toc items={tableOfContents} />
      <PostDescriptionWrapper containerWidth={containerWidth}>
        <PostDescription fontSizeDesktop={fontSizeDesktop}>
          <MDXRenderer>{description}</MDXRenderer>
        </PostDescription>
      </PostDescriptionWrapper>
      <GhostDiv />
    </PostDetailsWrapper>
  );
};

PostDetails.defaultProps = {};

export default PostDetails;
