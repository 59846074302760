import { themeGet } from '@styled-system/theme-get';
import React from 'react';
import styled from 'styled-components';
import { makeid } from '../../utils';

export interface Heading {
  title: string;
  url: number;
  items?: [Heading];
}

export type TocProps = {
  items: [Heading];
  enable?: boolean;
};

const TocWrapper = styled.div`
  position: sticky;
  position: -webkit-sticky; /* for Safari */
  max-width: 320px;
  top: 98px;
  align-self: flex-start;
  display: flex;
  flex-direction: column;
  margin-right: auto;
  margin-bottom: 100px;
  height: calc(100vh - 98px);
  border: 1px solid rgb(221, 221, 221);

  @media ${themeGet('mediaQueries.medium')} {
    display: none;
  }
  @media (max-width: 1700px) {
    margin-right: 30px;
  }
`;

const TocList = styled.ul`
  & > li > ul {
    padding-left: 15px !important;
  }
`;

const TocListWrapper = styled.div`
  padding-bottom: 10px;
  padding-top: 10px;
  overflow-y: scroll;

  > ul {
    padding-left: 36px;

    //Depth one
    > li {
      position: relative;

      &.side-toc--active {
        > a {
          &:after {
            opacity: 1;
            transform: scale(1);
          }
        }
      }

      &:hover {
        > a {
          &:after {
            opacity: 1;
            transform: scale(1);
          }
        }
      }

      &:not(.sub-list-item) {
        &:last-child {
          &:after {
            display: none;
          }
        }
      }

      &:after {
        content: '';
        display: block;
        position: absolute;
        height: 100%;
        width: 2px;
        background-color: #dddddd;
        top: 10px;
        left: -15px;
        pointer-events: none;
      }

      > a {
        position: relative;

        &:after,
        &:before {
          content: '';
          display: block;
          position: absolute;
          z-index: 1;
          pointer-events: none;
        }

        &:before {
          width: 16px;
          height: 16px;
          border-radius: 50%;
          border: 2px solid #dddddd;
          top: 0px;
          left: -32px;
          background-color: #fff;
        }

        &:after {
          content: '';
          display: block;
          position: absolute;
          width: 8px;
          height: 8px;
          border-radius: 50%;
          background-color: #9d9d9d;
          top: 4px;
          left: -28px;
          opacity: 0;
          transform: scale(0.3);
          transition: 0.15s ease-in-out;
        }
      }

      //Depth two
      > ul {
        margin-left: 18px;
        > li {
          position: relative;

          &.side-toc--active {
            > a {
              &:after {
                opacity: 1;
                transform: scale(1);
              }
            }
          }

          &:hover {
            > a {
              &:after {
                opacity: 1;
                transform: scale(1);
              }
            }
          }

          &:not(.sub-list-item) {
            &:last-child {
              &:after {
                display: none;
              }
            }
          }

          &:after {
            content: '';
            display: block;
            position: absolute;
            height: 100%;
            width: 2px;
            background-color: #dddddd;
            top: 10px;
            left: -16px;
            pointer-events: none;
          }

          > a {
            position: relative;

            &:after,
            &:before {
              content: '';
              display: block;
              position: absolute;
              z-index: 1;
              pointer-events: none;
            }

            &:before {
              width: 14px;
              height: 14px;
              border-radius: 50%;
              border: 2px solid #dddddd;
              top: 0px;
              left: -32px;
              background-color: #fff;
            }

            &:after {
              content: '';
              display: block;
              position: absolute;
              width: 6px;
              height: 6px;
              border-radius: 50%;
              background-color: #9d9d9d;
              top: 4px;
              left: -28px;
              opacity: 0;
              transform: scale(0.3);
              transition: 0.15s ease-in-out;
            }
          }
          //depth three
          > ul {
            margin-left: 18px;
            > li {
              position: relative;

              &.side-toc--active {
                > a {
                  &:after {
                    opacity: 1;
                    transform: scale(1);
                  }
                }
              }

              &:hover {
                > a {
                  &:after {
                    opacity: 1;
                    transform: scale(1);
                  }
                }
              }
              &:not(.sub-list-item) {
                &:last-child {
                  &:after {
                    display: none;
                  }
                }
              }
              &:after {
                content: '';
                display: block;
                position: absolute;
                height: 100%;
                width: 2px;
                background-color: #dddddd;
                top: 10px;
                left: -17px;
                pointer-events: none;
              }

              > a {
                position: relative;

                &:after,
                &:before {
                  content: '';
                  display: block;
                  position: absolute;
                  z-index: 1;
                  pointer-events: none;
                }

                &:before {
                  width: 12px;
                  height: 12px;
                  border-radius: 50%;
                  border: 2px solid #dddddd;
                  top: 0px;
                  left: -32px;
                  background-color: #fff;
                }

                &:after {
                  content: '';
                  display: block;
                  position: absolute;
                  width: 4px;
                  height: 4px;
                  border-radius: 50%;
                  background-color: #9d9d9d;
                  top: 4px;
                  left: -28px;
                  opacity: 0;
                  transform: scale(0.3);
                  transition: 0.15s ease-in-out;
                }
              }

              //depth four
              > ul {
                margin-left: 18px;
                > li {
                  position: relative;

                  &.side-toc--active {
                    > a {
                      &:after {
                        opacity: 1;
                        transform: scale(1);
                      }
                    }
                  }

                  &:hover {
                    > a {
                      &:after {
                        opacity: 1;
                        transform: scale(1);
                      }
                    }
                  }
                  &:not(.sub-list-item) {
                    &:last-child {
                      &:after {
                        display: none;
                      }
                    }
                  }
                  &:after {
                    content: '';
                    display: block;
                    position: absolute;
                    height: 100%;
                    width: 2px;
                    background-color: #dddddd;
                    top: 10px;
                    left: -18px;
                    pointer-events: none;
                  }

                  > a {
                    position: relative;

                    &:after,
                    &:before {
                      content: '';
                      display: block;
                      position: absolute;
                      z-index: 1;
                      pointer-events: none;
                    }

                    &:before {
                      width: 10px;
                      height: 10px;
                      border-radius: 50%;
                      border: 2px solid #dddddd;
                      top: 0px;
                      left: -32px;
                      background-color: #fff;
                    }

                    &:after {
                      content: '';
                      display: block;
                      position: absolute;
                      width: 6px;
                      height: 6px;
                      border-radius: 50%;
                      background-color: #9d9d9d;
                      top: 2px;
                      left: -30px;
                      opacity: 0;
                      transform: scale(0.3);
                      transition: 0.15s ease-in-out;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  &::-webkit-scrollbar-track {
    /* -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3); */
    background-color: transparent;
  }

  &::-webkit-scrollbar {
    width: 6px;
    background-color: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background-color: ${themeGet('colors.background')};
  }
`;

const TocItem = styled.li`
  list-style: none;
  display: flex;
  align-items: flex-start;
  padding: 10px 20px;
  padding-left: 10px;
  background-color: #fff;
  transition: background-color 0.15s ease;
`;

const TocSubListItem = styled.li`
  list-style: none;
`;

const TocLink = styled.a<{ depth?: number }>`
  color: black;
  font-size: 13px;
  overflow-wrap: break-word;
  display: block;
  &:hover {
    color: gray;
  }
  display: flex;
`;

const TocTitleWrapper = styled.div`
  border-bottom: 1px solid rgb(221, 221, 221);
`;

const TocTitle = styled.span`
  letter-spacing: 5px;
  color: black;
  text-transform: uppercase;
  font-size: 14px;
  font-family: ${themeGet('fontFamily.1', 'sans-serif')};
  padding: 10px;
  display: block;
  padding-bottom: 20px;
  padding-top: 20px;
`;

const ListComponent: React.FunctionComponent<{ items: [Heading] }> = ({
  items,
}) => {
  return (
    <TocList key={makeid(10)}>
      {items.map((item: Heading) => {
        const { title, url, items } = item;
        return (
          <React.Fragment key={makeid(10)}>
            <TocItem key={url}>
              <TocLink href={`${url}`}>{title}</TocLink>
            </TocItem>
            {items && (
              <TocSubListItem className="sub-list-item">
                <ListComponent items={items} />
              </TocSubListItem>
            )}
          </React.Fragment>
        );
      })}
    </TocList>
  );
};

const Toc: React.FunctionComponent<TocProps> = ({ items, enable = true }) => {
  if (!enable || !items)
    return <div style={{ width: '200px', marginRight: 'auto' }}></div>;

  // Initializing linkhighlight
  // useEffect(() => {
  //   initSideTocObserver(sideTocObserver);
  // },[])

  return (
    <TocWrapper>
      <TocTitleWrapper>
        <TocTitle>Table of Content</TocTitle>
      </TocTitleWrapper>
      <TocListWrapper id="side-toc">
        <ListComponent items={items} />
      </TocListWrapper>
    </TocWrapper>
  );
};

export default Toc;
