function findClosestHeading(element: HTMLElement) {
  const classes = element.classList;
  let isPostRoot = false;
  classes.forEach((cssClass) => {
    if (cssClass.startsWith("post-detailsstyle__PostDescriptionWrapper-")) {
      isPostRoot = true;
    }
  });

  if (isPostRoot) {
    // case when we failed to find heading element in scope of the post container
    return null;
  }

  const parent = element.parentElement;
  const siblings = parent?.childNodes || [];

  const siblingsBefore = [];
  let flagToStop = false;

  siblings.forEach((el) => {
    if (flagToStop) {
      return;
    }
    if (el === element) {
      flagToStop = true;
    } else {
      siblingsBefore.push(el);
    }
  });

  if (siblingsBefore.length > 0) {
    for (let i = siblingsBefore.length - 1; i !== 0; i--) {
      const nodeName = siblingsBefore[i].nodeName;
      if (["H1", "H2", "H3", "H4", "H5"].includes(nodeName)) {
        return siblingsBefore[i];
      }
    }
  } else {
    return findClosestHeading(parent);
  }
}

function isRelativeUrl(url = "") {
  return url.indexOf("http://") !== 0 && url.indexOf("https://") !== 0;
}

// UTM constants

const UTM_SOURCE = "gamasutra";
const UTM_MEDIUM = "post";

// changes a page to match gamasutra editor needs
// copies post content to clipboard
export default function friendlyCopy() {
  let params = new URLSearchParams(document.location.search.substring(1));
  if (params.get("copyFriendly") === "true") {
    try {
      //const domain = document.location.origin; // "https://thegamedev.guru"
      const domain = "https://thegamedev.guru"
      const pageUrl = document.location.pathname;

      // get last slug part
      const urlParts = pageUrl.split("/").filter((part) => part.length > 0);
      const lastSlugPart = (urlParts[urlParts.length - 2] + '_' + urlParts[urlParts.length - 1]).replace(/\-/g, '_');

      const utmParameters = `?utm_source=${UTM_SOURCE}&utm_medium=${UTM_MEDIUM}&utm_campaign=${lastSlugPart}`;

      const postContent = document.querySelector(`[class^="post-detailsstyle__PostDescription-"]`);

      if (!postContent) {
        throw new Error("copyFriendly: Failed to get post content");
      }

      // add title and ref to main blog
      const titleStr = document.querySelector(`[class^="post-detailsstyle__PostTitle-"]`).innerText;
      const titlePrefix = document.createElement("p");
      const textElement = document.createElement("span");
      textElement.innerText = `Check the original blog post at The Gamedev Guru: `;
      titlePrefix.appendChild(textElement);
      const refToMainBlog = document.createElement("a");
      refToMainBlog.setAttribute("href", `${domain}${pageUrl}${utmParameters}`);
      refToMainBlog.innerText = titleStr;
      titlePrefix.appendChild(refToMainBlog);
      postContent.prepend(titlePrefix);

      // videos modifications
      const allPostVideos = postContent.querySelectorAll(`[class^="Video__Wrapper-"] video`);
      allPostVideos.forEach((video) => {
        if (video.getAttribute("copyFriendly")) {
          return;
        }

        const sourceEl = video.querySelector("source");
        if (!sourceEl) {
          throw new Error("copyFriendly: Video without 'source' child");
        }
        const sourceElSrc = sourceEl.getAttribute("src");
        sourceEl.setAttribute("src", `${domain}${sourceElSrc}`);

        // set video with to 600
        if (video.clientWidth > 600) {
          video.setAttribute("width", `600`);
        }

        video.setAttribute("copyFriendly", "true");
      });

      const allPostNonComponentVideos = postContent.querySelectorAll(`video`);
      allPostNonComponentVideos.forEach((video) => {
        if (video.getAttribute("copyFriendly")) {
          return;
        }

        const sourceEl = video.querySelector("source");
        if (sourceEl) {
          const sourceElSrc = sourceEl.getAttribute("src");
          sourceEl.setAttribute("src", `${domain}${sourceElSrc}`);
        } else {
          if (video.getAttribute("src")) {
            video.setAttribute("src", `${domain}${video.getAttribute("src")}`);
          } else {
            throw new Error("copyFriendly: non component Video without 'source' child or 'src' attribute");
          }
        }

        // set video with to 600
        if (video.clientWidth > 600) {
          video.setAttribute("width", `600`);
        }

        video.setAttribute("copyFriendly", "true");
      });

      // image modifications
      const allPostImages = postContent.querySelectorAll(`.gatsby-image-wrapper, .gatsby-resp-image-wrapper`);
      allPostImages.forEach((image) => {
        const sourceEl = image.querySelector("picture img");
        if (!sourceEl) {
          throw new Error("copyFriendly: Image without 'picture img' child");
        }

        const sourceElSrc = sourceEl.getAttribute("src");
        const newImage = document.createElement("img");
        newImage.setAttribute("copyFriendly", "true");
        newImage.setAttribute("style", `height: 100%;`);
        newImage.setAttribute("alt", sourceEl.getAttribute("alt"));
        newImage.setAttribute("src", `${domain}${sourceElSrc}`);
        if (sourceEl.clientWidth > 566 && image.parentNode.nodeName === "FIGURE") {
          newImage.setAttribute("width", `566`);
        } else if (sourceEl.clientWidth > 600) {
          newImage.setAttribute("width", `600`);
        } else {
          newImage.setAttribute("width", sourceEl.clientWidth);
        }

        const closestHeading = findClosestHeading(image);
        // replace gatsby lazy loaded image with new element

        if (closestHeading) {
          const newRef = document.createElement("a");
          newRef.setAttribute("href", `${domain}${pageUrl}${utmParameters}#${closestHeading.getAttribute("id")}`);
          newRef.appendChild(newImage);

          image.parentNode.replaceChild(newRef, image);
        } else {
          //if heading was not found
          image.parentNode.replaceChild(newImage, image);
        }
      });

      const allNonComponentPostImages = postContent.querySelectorAll(`img`);
      allNonComponentPostImages.forEach((image) => {
        if (image.getAttribute("copyFriendly")) {
          return;
        }

        const imageSrc = image.getAttribute("src");
        image.setAttribute("src", isRelativeUrl(imageSrc) ? `${domain}${imageSrc}` : imageSrc);
        if (image.clientWidth > 600) {
          image.setAttribute("width", `600`);
        }

        image.setAttribute("copyFriendly", "true");
      });

      // table of content modifications
      const allPostTocs = postContent.querySelectorAll(`[class^="MarkdownToc__TocWrapper-"]`);
      allPostTocs.forEach((toc) => {
        if (toc.getAttribute("copyFriendly")) {
          return;
        }
        // bold header:
        const header = toc.querySelector(`[class^="MarkdownToc__TocTitle-"]`);
        const headerStr = header.innerHTML;
        // make it bold
        header.innerHTML = `<b>${headerStr}</b>`;

        const refEls = toc.querySelectorAll("a");

        refEls.forEach((refEl) => {
          const url = refEl.getAttribute("href");

          refEl.setAttribute("href", `${domain}${pageUrl}${utmParameters}${url}`);
        });

        toc.setAttribute("copyFriendly", "true");
      });

      // handle image comparison element
      const allPostComparisons = postContent.querySelectorAll(`[class^="styles__ImageSliderWrapper-"]`);
      allPostComparisons.forEach((comparison) => {
        if (comparison.getAttribute("copyFriendly")) {
          return;
        }

        const altHolder = comparison.querySelector(`[class^="styles__AltContainer-"]`);
        comparison.removeChild(altHolder);

        const switchElement = comparison.querySelector(`input`);
        comparison.removeChild(switchElement);

        const refToInteractiveComparison = document.createElement("a");
        const closestHeading = findClosestHeading(comparison);
        if (closestHeading) {
          refToInteractiveComparison.setAttribute(
            "href",
            `${domain}${pageUrl}${utmParameters}#${closestHeading.getAttribute("id")}`
          );
        } else {
          refToInteractiveComparison.setAttribute("href", `${domain}${pageUrl}${utmParameters}`);
        }
        refToInteractiveComparison.innerText = `Experiment with the interactive comparison here`;
        comparison.appendChild(refToInteractiveComparison);

        comparison.setAttribute("copyFriendly", "true");
      });

      // handle bonus content section
      const allBonusElements = postContent.querySelectorAll(`[class^="BonusContent__Wrapper-"]`);
      allBonusElements.forEach((element) => {
        if (element.getAttribute("copyFriendly")) {
          return;
        }

        const wrapperTextContent = element.querySelector(`[class^="BonusContent__WrapperText-"]`);
        const bonusWrapperContent = element.querySelector(`script[type="text/x-template"]`);

        element.innerHTML = "";

        const newContent = document.createElement("div");
        newContent.innerHTML = bonusWrapperContent.innerHTML;

        element.append(wrapperTextContent);
        element.append(newContent);

        element.setAttribute("copyFriendly", "true");
      });

      // replace problematic characters
      let content = postContent.innerHTML;
      content = content.replaceAll("…", "...");

      navigator.clipboard
        .write([
          new ClipboardItem({
            "text/html": new Blob([content], { type: "text/html" }),
          }),
        ])
        .then(() => {
          console.log("copyFriendly feature - post is copied");
          alert("copyFriendly: post was copied to clipboard");
        })
        .catch((err) => {
          console.log("copyFriendly feature clipboard error:", err);
        });
    } catch (err) {
      console.log("copyFriendly feature JS error:", err);
    }
  }
}
