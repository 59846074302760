import  React from 'react';
import Image from 'gatsby-image';


type ThumbnailProps = {
  image:any;
  imageType: string,
}

const Thumbnail:React.FunctionComponent<ThumbnailProps> = (props) => {

  const {image, imageType } = props;

 
  if (imageType === 'fluid')
    return <Image fluid={image}  />

  if (imageType === 'fixed')
    return <Image fixed={image}  />

  if (imageType === 'image')
    return <img src={image}  />

  if (imageType === 'video')
    return <video src={image} autoPlay muted loop />

  return null

}


export default Thumbnail;