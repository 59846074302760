import styled from 'styled-components';
import { themeGet } from '@styled-system/theme-get';

export const PostDetailsWrapper = styled.div`
  display: flex;
  margin-top: 40px;

  @media ${themeGet('mediaQueries.medium')} {
    flex-direction: column;
  }

  .gatsby-highlight {
    background-color: #fdf6e3;
    border-radius: 0.3em;
    margin: 0.5em 0;
    overflow: auto;
    border-left: 10px solid #358ccb;
    box-shadow: -1px 0px 0px 0px #358ccb, 0px 0px 0px 1px #dfdfdf;
  }

  .gatsby-highlight pre[class*='language-'] {
    margin: 0;
  }

  .gatsby-highlight pre[class*='language-'].line-numbers.line-numbers code {
    padding-left: 16px;
  }

  .gatsby-highlight pre[class*='language-'].line-numbers {
    padding: 0;
    padding-left: 2.8em;
  }

  .gatsby-highlight pre[class*="language-"]:before {
    z-index: -2;
  }

  .gatsby-highlight pre[class*="language-"]:after {
    z-index: -2;
  }

  .gatsby-highlight .line-numbers-rows {
    padding-left: 15px;
  }

  .gatsby-highlight pre[class*='language-'] > code {
    border-left: none;
    box-shadow: none;
  }
`;

export const PostTitle = styled.h1<{ fontSizeDesktop: any }>`
  font-size: ${(props) => (props.fontSizeDesktop ? `${props.fontSizeDesktop + 22}px` : "40px")};
  font-weight: 700;
  color: black;
  font-family: ${themeGet("fontFamily.2", "serif")};
`;

export const PostDate = styled.span`
  display: block;
  font-size: ${themeGet('fontSizes.3', '15')}px;
  color: ${themeGet('textColor', '#292929')};
  font-weight: 400;
  text-transform: uppercase;
  @media (max-width: 990px) {
    font-size: 14px;
  }
  @media (max-width: 575px) {
    font-size: 13px;
  }
`;

export const PostPreview = styled.div`
  margin-top: 45px;
  position: relative;
  @media (max-width: 1200px) {
    margin-top: 40px;
  }
  @media (max-width: 575px) {
    margin-top: 35px;
  }

  img {
    border-radius: 3px;
  }

  &:before {
    content: '';
    position: absolute;
    width: 80%;
    height: 80%;
    background-color: #757575;
    bottom: 0;
    left: 10%;
    filter: blur(15px);
  }
`;

export const PostDescriptionWrapper = styled.div<{ containerWidth: any }>`
  max-width: ${(props) =>
    props.containerWidth ? props.containerWidth : '700px'};
  margin: 0 auto;
  width: 100%;
`;

export const PostDescription = styled.div<{ fontSizeDesktop: any }>`
  font-size: ${themeGet('fontSizes.4', '16')}px;

  .mermaid {
    margin-bottom: 60px;
    @media (max-width: 767px) {
      margin-bottom: 40px;
    }
  }

  > * {
    z-index: 1;
  }

  p {
    font-size: ${themeGet('fontSizes.4', '16')}px;
    margin-bottom: 2em;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    z-index: 0;
    font-weight: 600;
    margin-bottom: 0.75em;
    font-family: ${themeGet('fontFamily.2', 'serif')};
  }

  h2,
  h3,
  h4,
  h5,
  h6 {
    padding-top: 100px;
    margin-top: -100px;
    position: static !important;
    @media ${themeGet('mediaQueries.medium')} {
      padding-top: 0;
      margin-top: 0;
    }
  }

  h1 {
    font-size: 30px;
    margin-bottom: 0.75em;
  }

  h2 {
    font-weight: 800;
    font-size: 28px;
    margin-bottom: 0.75em;
  }

  h3 {
    font-size: 23px;
  }

  h4 {
    font-size: 17px;
  }

  h5 {
    font-size: 15px;
  }

  h6 {
    font-size: 13px;
  }

  ol,
  ul {
    margin-left: 1.5rem !important;
    margin-bottom: 2rem;
    line-height: 2 !important;
    padding-left: 2rem;
  }

  li {
    margin-bottom: 0.45rem;

    p {
      margin-bottom: 1em;
    }
  }

  blockquote {
    font-family: ${themeGet('fontFamily.0', 'sans-serif')};
    font-size: 21px;
    font-weight: 500;
    line-height: 2;
    margin: 60px 0;
    @media (max-width: 1200px) {
      margin: 50px 0;
      font-size: 19px;
    }
    @media (max-width: 575px) {
      margin: 40px 0;
      font-size: 15px;
    }
    &:before,
    &:after {
      content: '';
      width: 30px;
      height: 1px;
      display: block;
      background: #292929;
    }
    &:before {
      margin-bottom: 60px;
      @media (max-width: 1200px) {
        margin-bottom: 50px;
      }
      @media (max-width: 575px) {
        margin-bottom: 40px;
      }
    }
    &:after {
      margin-top: 60px;
      @media (max-width: 1200px) {
        margin-top: 50px;
      }
      @media (max-width: 575px) {
        margin-top: 40px;
      }
    }

    p {
      font-size: 21px;
      font-weight: 500;
      line-height: 2;
      margin-bottom: 23px;
      @media (max-width: 1200px) {
        font-size: 19px;
      }
      @media (max-width: 1200px) {
        font-size: 17px;
      }
      @media (max-width: 575px) {
        font-size: 15px;
      }
      &:last-child {
        margin-bottom: 0;
      }
    }
    h4 {
      font-size: 16px;
      margin: 0;
      font-family: ${themeGet('fontFamily.1', 'sans-serif')};
      font-weight: 400;
    }
  }

  a {
    font-weight: 500;
    transition: 0.15s ease-in-out;
    color: ${themeGet('primary', '#A63503')};
    text-decoration: underline;
  }

  video {
    max-width: 100%;
    width: 100%;
  }

  @media (min-width: 768px) {
    p {
      font-size: ${(props) => (props.fontSizeDesktop ? `${props.fontSizeDesktop - 2}px` : "16px")};
    }
    h1 {
      font-size: ${(props) => (props.fontSizeDesktop ? `${props.fontSizeDesktop + 12}px` : "30px")};
    }

    h2 {
      font-size: ${(props) => (props.fontSizeDesktop ? `${props.fontSizeDesktop + 10}px` : "28px")};
    }

    h3 {
      font-size: ${(props) => (props.fontSizeDesktop ? `${props.fontSizeDesktop + 5}px` : "23px")};
    }

    h4 {
      font-size: ${(props) => (props.fontSizeDesktop ? `${props.fontSizeDesktop - 1}px` : "17px")};
    }

    h5 {
      font-size: ${(props) => (props.fontSizeDesktop ? `${props.fontSizeDesktop - 3}px` : "15px")};
    }

    h6 {
      font-size: ${(props) => (props.fontSizeDesktop ? `${props.fontSizeDesktop - 5}px` : "13px")};
    }

    blockquote {
      font-size: ${(props) => (props.fontSizeDesktop ? `${props.fontSizeDesktop + 3}px` : "21px")};
      p {
        font-size: ${(props) => (props.fontSizeDesktop ? `${props.fontSizeDesktop + 3}px` : "21px")};
      }
      h4 {
        font-size: ${(props) => (props.fontSizeDesktop ? `${props.fontSizeDesktop + 2}px` : "16px")};
      }
    }
  }
`;

export const PostTags = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-top: 60px;

  a {
    display: block;
    margin-right: 30px;
    font-size: 14px;
    font-weight: 400;
    color: ${themeGet('primary', '#D10068')};
  }
`;

export const PostAuthor = styled.div`
  display: flex;
  align-items: center;
`;

export const PostAuthorThumb = styled.div`
  width: 65px;
  flex-shrink: 0;
  margin-right: 20px;
`;

export const PostAuthorInfo = styled.div``;

export const PostAuthorText = styled.p`
  margin-bottom: 0;
  color: black;
  font-family: ${themeGet('fontFamily.1', 'sans-serif')};
  font-size: 12px;
  span {
    color: rgb(153, 153, 153) !important;
  }
`;
export const PostAuthorCategory = styled(PostAuthorText)`
  color: rgb(0, 73, 200);
  font-weight: 400;
  display: flex;
  align-items: center;
  span {
    margin-right: 5px;
    font-size: 14px;
    color: rgb(0, 73, 200) !important;
  }

  a {
    color: rgb(0, 73, 200);

    &:hover {
      text-decoration: underline;
    }

    &:not(:last-child) {
      margin-right: 3px;
      &::after {
        content: ',';
      }
    }
  }
`;

export const PostDivider = styled.hr`
  margin-top: 30px;
  border-top: 1px dotted lightgray;
  border-left: 0;
  border-right: 0;
  border-bottom: 0;
  margin-bottom: 0;
`;

export const GhostDiv = styled.div`
  max-width: 320px;
  width: 100%;
  visibility: hidden;
  margin-left: auto;

  @media (max-width: 1700px) {
    display: none;
  }
`;

export const PostShareIcons = styled.div``;
