import React, { useEffect } from 'react';
import { graphql, Link } from 'gatsby';
import Img from 'gatsby-image';
import _ from 'lodash';
import Layout from '../components/layout';
import SEO from '../components/seo';
import PostDetails from '../components/post-details/post-details';
import {
  IoLogoFacebook,
  IoLogoLinkedin,
  IoLogoTwitter,
  IoMdPricetag,
} from 'react-icons/io';
import {
  BlogPostDetailsWrapper,
  PostHeader,
  PostHeaderImage,
  PostHeaderInfo,
  PostHeaderText,
  PostHeaderContainer,
  BlogPostWrapper,
} from './templates.style';
import {
  PostAuthor,
  PostAuthorInfo,
  PostAuthorText,
  PostAuthorCategory,
  PostAuthorThumb,
  PostDivider,
  PostTitle,
} from '../components/post-details/post-details.style';
import {
  FacebookShareButton,
  TwitterShareButton,
  LinkedinShareButton,
} from 'react-share';
import urljoin from 'url-join';
import Thumbnail from '../components/thumbnail';
import { getImage, makeid } from '../utils';
import friendlyCopy from '../utils/friendly-copy';
import Broadcast from '../components/blog/Broadcast';

// import test from '../scripts/test';
// import ScriptTag from 'react-script-tag';

const BlogPostTemplate = (props: any) => {
  const post = props.data.mdx;
  const slug = post.fields.slug;
  const siteUrl = props.data.site.siteMetadata.siteUrl;
  const shareUrl = urljoin(siteUrl, slug);

  // Effect for initializing toc hightlight
  useEffect(() => {
    const sideTocObserver = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          const id = entry.target.getAttribute('id');
          if (entry.isIntersecting) {
            document
              .querySelectorAll('.side-toc--active')
              .forEach((element) =>
                element.classList.remove('side-toc--active')
              );
            document
              .querySelector(`a[href="#${id}"]`)
              ?.parentElement?.classList?.add('side-toc--active');
          }
        });
      },
      {
        rootMargin: '105px 0% 0% 0%',
      }
    );

    const initSideTocObserver = (observer: IntersectionObserver) => {
      document
        .querySelectorAll('h2[id], h3[id], h4[id], h5[id], h6[id]')
        .forEach((elm) => {
          observer.observe(elm);
        });
    };

    const cleanSideTocObserver = (observer: IntersectionObserver) => {
      document
        .querySelectorAll('h2[id], h3[id], h4[id], h5[id], h6[id]')
        .forEach((elm) => {
          observer.unobserve(elm);
        });
    };

    initSideTocObserver(sideTocObserver);

    const isSSR = typeof window === 'undefined';
    if (!isSSR) {
      friendlyCopy();
    }

    return () => {
      cleanSideTocObserver(sideTocObserver);
    };
  }, []);

  // Effect for add the script

  const {
    thumbnail,
    videoThumbnail,
    title,
    containerWidth,
    category,
    featuredImage,
  } = post.frontmatter;

  const fontSizeDesktop = post?.frontmatter?.fontSizeDesktop;

  const postThumbnail = thumbnail != null ? thumbnail : videoThumbnail;

  const { image, imageType } = getImage(postThumbnail);

  return (
    <Layout>
      <SEO
        title={post.frontmatter.title}
        description={post.frontmatter.excerpt}
        url={shareUrl}
        thumbnail={siteUrl + postThumbnail.publicURL}
        featuredImage={
          featuredImage ? siteUrl + featuredImage.publicURL : undefined
        }
      />
      <PostHeader>
        <PostHeaderContainer>
          <PostHeaderImage>
            <Thumbnail image={image} imageType={imageType} />
          </PostHeaderImage>
          <PostHeaderInfo>
            {post.frontmatter.date && (
              <PostHeaderText>
                {post.frontmatter.date.replace(',', ' ')}
              </PostHeaderText>
            )}
          </PostHeaderInfo>
        </PostHeaderContainer>
      </PostHeader>
      <Broadcast />
      <BlogPostWrapper>
        <BlogPostDetailsWrapper>
          <PostTitle fontSizeDesktop={fontSizeDesktop}>{post.frontmatter.title}</PostTitle>
          <PostAuthor>
            <PostAuthorThumb>
              <Img
                fluid={props.data.thumbnail.childImageSharp.fluid}
                alt="Ruben Torres Bonet"
              />
            </PostAuthorThumb>
            <PostAuthorInfo>
              <PostAuthorText>
                <span>By</span> {post.frontmatter.author}
              </PostAuthorText>
              <PostAuthorText>
                <span>{post.frontmatter.date}</span>
              </PostAuthorText>
              <PostAuthorCategory>
                <span>
                  <IoMdPricetag />
                </span>
                {category?.map((cat: string) => (
                  <Link to={`/${_.kebabCase(cat)}`} key={makeid(10)}>
                    {cat}
                  </Link>
                ))}
              </PostAuthorCategory>
            </PostAuthorInfo>
          </PostAuthor>
          <PostDivider />

          <FacebookShareButton url={shareUrl} quote={post.excerpt}>
            <IoLogoFacebook />
            Share
          </FacebookShareButton>
          <TwitterShareButton url={shareUrl} title={title}>
            <IoLogoTwitter />
            Tweet
          </TwitterShareButton>

          <LinkedinShareButton url={shareUrl}>
            <IoLogoLinkedin />
            Share
          </LinkedinShareButton>
        </BlogPostDetailsWrapper>

        <PostDetails
          title={post.frontmatter.title}
          date={post.frontmatter.date}
          description={post.body}
          category={post.frontmatter.category}
          author={post.frontmatter.author}
          tableOfContents={post.tableOfContents.items}
          containerWidth={containerWidth}
          fontSizeDesktop={fontSizeDesktop}
        />

      </BlogPostWrapper>
      <Broadcast />
    </Layout>
  );
};

export default BlogPostTemplate;

export const pageQuery = graphql`
  query BlogPostBySlug($slug: String!) {
    thumbnail: file(absolutePath: { regex: "assets/ruben/ruben-assassins-creed-dev.webp/" }) {
      childImageSharp {
        fluid(maxWidth: 100, quality: 90) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    site {
      siteMetadata {
        siteUrl
      }
    }
    mdx(fields: { slug: { eq: $slug } }) {
      id
      excerpt(pruneLength: 160)
      body
      tableOfContents
      fields {
        slug
      }
      frontmatter {
        author
        category
        containerWidth
        fontSizeDesktop
        date(formatString: "MMMM DD, YYYY")
        excerpt
        featuredImage {
          publicURL
        }
        title
        toc
        videoThumbnail {
          publicURL
          extension
        }
        thumbnail {
          publicURL
          extension
          childImageSharp {
            fluid(maxWidth: 800, quality: 90) {
              ...GatsbyImageSharpFluid_withWebp_noBase64
            }
          }
        }
      }
    }
  }
`;
